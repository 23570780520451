import { PublicLayout } from '@layouts';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { ProductListContainer } from '@containers/products';
import { useMetaTags } from '@hooks/common';
import { NextPageWithLayout } from '@interfaces/common';
import { buildTagHTML } from '@utils/seo';

const ProductListPage: NextPageWithLayout = ({ brandUrl }) => {
  const { t } = useTranslation();
  const seoTags = useMetaTags({ pageTitle: t('products.title'), brandUrl });

  return (
    <>
      <Head>{buildTagHTML(seoTags)}</Head>
      <ProductListContainer />
    </>
  );
};

ProductListPage.getLayout = function getLayout(page) {
  return <PublicLayout>{page}</PublicLayout>;
};

export { getServerSideProps } from '@utils/seo';
export default ProductListPage;
